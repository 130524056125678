import * as React from "react"
import AelWizard from "./aelWizard"
import AelPreferenceCheckbox from "./aelPreferenceCheckbox"
import MgcTextField from "./mgcTextField"
import GAnalyticsService from "../services/gAnalyticsService"
import { hasNumber, hasOnlyNumbers } from "../utils/regexTests"

class AelStep5 extends React.Component {
  constructor(props) {
    super(props)
    this.displayParrainage = this.displayParrainage.bind(this)
    this.displayParrainFields = this.displayParrainFields.bind(this)
  }
  componentDidMount() {
    const { validateForm, values } = this.props
    const { codeGamme } = values

    if (codeGamme) {
      GAnalyticsService.sendPageviw(`/mgc-adhesion-${codeGamme}-etape5`)
    }

    validateForm()
    const screenSize = window.innerWidth
    if (screenSize < 481) {
      window.scrollTo(0, 200)
    } else if (screenSize < 769) {
      window.scrollTo(0, 225)
    } else {
      window.scrollTo(0, 0)
    }
  }

  displayParrainage() {
    const { adherentTnsToCheck } = this.props.values
    return !adherentTnsToCheck
  }

  displayParrainFields() {
    const { preference } = this.props.values || {}
    const { hasParrain } = preference || {}
    return !!hasParrain
  }

  render() {
    const { values, dirty, errors, rollback, disabled, done, setFieldValue } = this.props

    const preferenceErrors = errors.preference || {}
    return (
      <AelWizard.Step>
        <div className="aelstep5">
          <div className="form-row row">
            <div className="title-rollback-ael  offset-1">
              <h2 className={"step-title"}>Mes préférences</h2>
            </div>
            <button
              type="button"
              className="btn-rollback-ael"
              onClick={() => rollback(4)}
              hidden={!done}
            >
              {disabled ? "Modifier" : "Consulter"}
            </button>
          </div>
          <div className="row">
            <h3 className="col-11 offset-1">
              Informations communiquées par voie électronique ou tout autre
              support durable
          </h3>
            <p className="col-11 offset-1">
              Vous recevrez des informations de la part de la Mutuelle sur
              l’adresse e-mail que vous nous communiquez.
          </p>
          </div>
          <AelPreferenceCheckbox
            label={
              "Si vous ne souhaitez pas recevoir vos décomptes de remboursement par voie électronique, cochez cette case"
            }
            name={"consultationDecompte"}
            values={values}
            disabled={disabled}
          />
          <AelPreferenceCheckbox
            name={"noInformationDematerialisable"}
            label={
              "Sachez que la Mutuelle se réserve le droit de vous notifier les modifications des statuts et du règlement mutualiste au moyen de tout support durable autre que le papier (votre espace adhérent, e-mail…).\n" +
              "Si vous ne souhaitez pas être informé(e) de ces modifications par voie dématérialisée, cochez cette case"
            }
            values={values}
            disabled={disabled}
          />
          <AelPreferenceCheckbox
            name={"noElectonicNewsletter"}
            label={
              "Si vous ne souhaitez pas recevoir nos newsletters par voie électronique, cochez cette case"
            }
            values={values}
            disabled={disabled}
          />
          <AelPreferenceCheckbox
            name={"noElectonicPromotions"}
            label={
              "Si vous ne souhaitez pas recevoir nos promotions et sollicitations par voie électronique, cochez cette case"
            }
            values={values}
            disabled={disabled}
          />
          <div className="row">
            <h3 className="col-11 offset-1">Consentement de l’adhérent</h3>
          </div>
          <AelPreferenceCheckbox
            name={"evenementsJeuxConcours"}
            label={
              "Si vous souhaitez recevoir, y compris par voie électronique, des informations sur les événements et les jeux-concours organisés par la MGC, cochez cette case"
            }
            values={values}
            disabled={disabled}
          />
          <AelPreferenceCheckbox
            name={"offresPartenaires"}
            label={
              "Si vous souhaitez recevoir, y compris par voie électronique, les offres de nos partenaires proposées par la MGC (prévoyance…), cochez cette case"
            }
            values={values}
            disabled={disabled}
          />
          <div className="parrain" hidden={!this.displayParrainage()}>
            <div className="row">
              <h3 className="col-11 offset-1">Parrainage</h3>
            </div>
            <AelPreferenceCheckbox
              label={"Je suis parrainé par un adhérent MGC"}
              name={"hasParrain"}
              values={values}
              disabled={disabled}
            />
            <MgcTextField
              prefix={"preference."}
              name={"nomParrain"}
              hidden={!this.displayParrainFields()}
              placeholder={"Nom"}
              dirty={dirty}
              errors={preferenceErrors}
              disabled={disabled}
              onChange={e => {
                if (!hasNumber(e.target.value)) {
                  setFieldValue(e.target.name, e.target.value)
                }
              }}
            />
            <MgcTextField
              prefix={"preference."}
              name={"prenomParrain"}
              hidden={!this.displayParrainFields()}
              placeholder={"Prénom"}
              errors={preferenceErrors}
              dirty={dirty}
              disabled={disabled}
              onChange={e => {
                if (!hasNumber(e.target.value)) {
                  setFieldValue(e.target.name, e.target.value)
                }
              }}
            />
            <MgcTextField
              prefix={"preference."}
              name={"cpParrain"}
              hidden={!this.displayParrainFields()}
              placeholder={"Code Postal"}
              errors={preferenceErrors}
              dirty={dirty}
              disabled={disabled}
              onChange={e => {
                if (hasOnlyNumbers(e.target.value)) {
                  setFieldValue(e.target.name, e.target.value)
                }
              }}
            />
          </div>
        </div>
      </AelWizard.Step>
    )
  }
}

export default AelStep5
