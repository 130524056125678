import * as React from "react"
import {
  ButtonGroup,
  DropdownButton,
  Dropdown,
  ToggleButtonGroup,
  ToggleButton,
} from "react-bootstrap"
import MgcSelectField from "./mgcSelectField"
import Moment from "moment"
import { useEffect } from "react"

const AelStep2Dates = ({
  datesRapides,
  dateAutres,
  onSelect,
  dirty,
  errors,
  setFieldValue,
  currentDate,
  disabled
}) => {
  const allDates = datesRapides.concat(dateAutres)

  //TODO-IMPROVE éviter autant que possible de manipuler le DOM
  useEffect(()=> {
    let element = document.getElementById("other-dates")
    if (dateAutres &&
    dateAutres.find((e) => {
      return e.date === currentDate
    })){
      let activeElements = document.getElementsByClassName("btn-outline-secondary active")
      activeElements && activeElements.length > 0 && activeElements[0].classList.remove("active")
      element && element.classList.add("active")
    } else {
      element && element.classList.remove("active")
    }
  }, [currentDate, dateAutres])

  return (
    <>
      <div className="form-row">
        <label className="offset-1">
          Date d'effet des garanties souhaitée le: <strong hidden={!disabled}>{currentDate && Moment(currentDate).format("Do MMMM YYYY")}</strong>
        </label>
      </div>
      <div className="row" style={{marginLeft: "-5px"}} hidden={disabled}>
        <div className="offset-1 d-none d-md-block">
          <ToggleButtonGroup onChange={onSelect} name="dtDebut" defaultValue={currentDate}>
            {datesRapides.map(d => (
              <ToggleButton
                key={d.date}
                variant="outline-secondary"
                value={d.date}
                disabled={disabled}
              >
                {d.display}
              </ToggleButton>
            ))}
            {dateAutres && dateAutres.length > 0 && (
              <DropdownButton
                as={ButtonGroup}
                title="Autre date"
                drop="down"
                variant="outline-secondary"
                onSelect={onSelect}
                disabled={disabled}
                id={"other-dates"}
              >
                {dateAutres.map(da => (
                  <Dropdown.Item key={da.date} eventKey={da.date} disabled={disabled}>
                    {da.display}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            )}
          </ToggleButtonGroup>
        </div>
        <div className="offset-1 d-block d-md-none">
          <MgcSelectField
            errors={errors}
            dirty={dirty}
            valueField={"date"}
            labelField={"display"}
            table={allDates}
            firstOption={"- Choisir une date -"}
            setFieldValue={setFieldValue}
            name={"dtDebut"}
            onChange={onSelect}
            disabled={disabled}
          />
        </div>
      </div>
      <div className="form-row mt-2">
        <p className="offset-1">
          Certaines prestations font l'objet d'un délai d'attente mentionné
          dans le tableau des garanties.
        </p>
        <p className="offset-1">
          Vous souhaitez une date d'effet ultérieure ? Appelez-nous au 01 40
          78 57 10 (du lundi au jeudi de 9h00 à 18h00 et le vendredi de 9h00 à 17h30) ou
          au 32 80 06 (réservé aux salariés SNCF)
        </p>
      </div>
    </>
  )
}

export default AelStep2Dates
