import { Field } from "formik"
import React, { useEffect } from "react"
import MgcTextField from "./mgcTextField"
import MgcRadioToggleButtonGroup from "./mgcRadioToggleButtonGroup"
import MgcRadioToggleButton from "./mgcRadioToggleButton"
import MgcSelectField from "./mgcSelectField"
import MgcNewDateField from "./mgcNewDateField"
import { hasOnlyNumbers } from "../utils/regexTests"

const AelAgentTerritorial = ({
  errors,
  dirty,
  hidden,
  values,
  touched,
  disabled,
  setFieldValue,
}) => {
  useEffect(() => {
    if (!values.agentTerritorialDateFinDernierContrat) {
      values.agentTerritorialDateFinDernierContrat = ""
    }
    if (!values.agentTerritorialCodePostalEmployeur) {
      values.agentTerritorialCodePostalEmployeur = ""
    }
  }, [])

  const tauxMajorationList = [
    { id: "0%", libelle: "0%" },
    { id: "2%", libelle: "2%" },
    { id: "4%", libelle: "4%" },
  ]

  const onChangeWithReset = (e) => {
    let updatedValue = e.target.value
    if (updatedValue === "true" || updatedValue === "false") {
      updatedValue = JSON.parse(updatedValue)
    }
    setFieldValue(e.target.name, updatedValue)
  }

  return (
    <div hidden={hidden}>
      <MgcTextField
        dirty={dirty}
        errors={errors}
        name="agentTerritorialEmployeur"
        placeholder="Employeur"
        disabled={disabled}
      />
      <MgcTextField
        dirty={dirty}
        errors={errors}
        name="agentTerritorialCodePostalEmployeur"
        placeholder="Code postal de l'employeur"
        disabled={disabled}
        onChange={e => {
          if (hasOnlyNumbers(e.target.value)) {
            setFieldValue(e.target.name, e.target.value)
          }
        }}
      />
      <label className="col-11 offset-1">
        Avez-vous déjà été couvert par une complémentaire santé labellisée ?
      </label>
      <MgcRadioToggleButtonGroup
        id="agentTerritorialDejaCouvert"
        value={values.agentTerritorialDejaCouvert}
        error={errors.agentTerritorialDejaCouvert}
        touched={touched.agentTerritorialDejaCouvert}
      >
        <Field
          component={MgcRadioToggleButton}
          name="agentTerritorialDejaCouvert"
          id="true"
          label={"Oui"}
          disabled={disabled}
          onChangeWithReset={onChangeWithReset}
        />
        <Field
          component={MgcRadioToggleButton}
          name="agentTerritorialDejaCouvert"
          id="false"
          label={"Non"}
          disabled={disabled}
          onChangeWithReset={onChangeWithReset}
        />
      </MgcRadioToggleButtonGroup>
      <div hidden={values.agentTerritorialDejaCouvert !== true}>
        <MgcNewDateField
          dirty={dirty}
          errors={errors}
          name="agentTerritorialDateFinDernierContrat"
          label="Date de fin du dernier contrat labellisé"
          disabled={disabled}
          values={values}
          setFieldValue={setFieldValue}
        />
        <MgcSelectField
          errors={errors}
          dirty={dirty}
          name="agentTerritorialTauxMajoration"
          /*valueField="value"
          labelField="value"*/
          table={tauxMajorationList}
          firstOption="- Taux de majoration du dernier contrat labellisé -"
          withOffset={true}
          disabled={disabled}
          setFieldValue={setFieldValue}
        />
      </div>
      <div hidden={values.agentTerritorialDejaCouvert !== false}>
        <MgcNewDateField
          dirty={dirty}
          errors={errors}
          name="agentTerritorialDateEntree"
          label="Date d'entrée dans la fonction publique"
          disabled={disabled}
          values={values}
          setFieldValue={setFieldValue}
        />
      </div>
    </div>
  )
}

export default AelAgentTerritorial
