import { Field } from "formik"
import * as React from "react"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Tooltip from "react-bootstrap/Tooltip"
import informationIcon from "../images/icon-information.png";

const MgcEmailField = ({name, placeholder, errors, dirty, hidden, tooltip, disabled}) => (
  <div className="form-row" hidden={hidden}>
    <div className="col-1 text-right">
      {tooltip && <OverlayTrigger overlay={(<Tooltip id="title-tooltip" placement={"right"}>
        <p>{tooltip}</p>
      </Tooltip>)}><img src={informationIcon} className="icon-info-ael"/></OverlayTrigger>}
    </div>
    <div className="col-10 form-group">
      <Field type="email" name={name} className="form-control" placeholder={placeholder} disabled={disabled} />
    </div>
    <div className="col-1">
      <span className={`${errors[name] || !dirty  ? 'fa-times text-danger': 'fa-check text-success'} fa-2x fa`}/>
    </div>
  </div>
);

export default MgcEmailField;
