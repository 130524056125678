import * as React from "react"
import AelPersonne from "./aelPersonne"
import AelConjointRattachement from "./aelConjointRattachement"
import MgcSelectField from "./mgcSelectField"
import MgcAutoComplete from "./mgcAutocomplete"
import MgcTextField from "./mgcTextField"
import MgcNssField from "./mgcNssField"

const AelConjoint = ({
  errors,
  dirty,
  values,
  touched,
  setFieldValue,
  onChangeRegime,
  onChangeSituation,
  onChangeOrganisme,
  organismes,
  regimes,
  situations,
  disabled,
}) => {
  const hideNSSConjoint = ({ conjointCivilite, conjointRattachement }) => {
    return (
      values.conjointCivilite === "no" ||
      (values.conjointRattachement === "true" ||
        values.conjointRattachement === true)
    )
  }
  const adherentNSS = values.adherentNumeroSecuriteSociale || "N° à saisir"
  return (
    <>
      <AelPersonne
        setFieldValue={setFieldValue}
        prefix="conjoint"
        touched={touched}
        errors={errors}
        dirty={dirty}
        values={values}
        withNo={true}
        title="Conjoint"
        disabled={disabled}
      />
      <AelConjointRattachement
        touched={touched}
        values={values}
        dirty={dirty}
        errors={errors}
        setFieldValue={setFieldValue}
        hidden={values.conjointCivilite === "no"}
        disabled={disabled}
        adherentNSS={adherentNSS}
      />
      <MgcSelectField
        setFieldValue={setFieldValue}
        errors={errors}
        dirty={dirty}
        withOffset={true}
        onChange={onChangeRegime}
        disabled={disabled}
        table={regimes}
        name="conjointRegimeAssurance"
        firstOption="- Régime d'assurance maladie -"
        hidden={hideNSSConjoint(values)}
        labelField={"libelle"}
        valueField={"code"}
      />
      <MgcNssField
        hidden={hideNSSConjoint(values)}
        dirty={dirty}
        errors={errors}
        name="conjointNumeroSecuriteSociale"
        placeholder="Numéro de sécurité sociale"
        disabled={disabled}
      />
      <MgcAutoComplete
        onChange={onChangeOrganisme}
        errors={errors}
        dirty={dirty}
        name="conjointOrganismeAffilliation"
        labelField="display"
        setFieldValue={setFieldValue}
        items={organismes}
        placeholder="Code organisme de rattachement"
        values={values}
        initValue={
          values.conjointOrganismeAffilliation
            ? values.conjointOrganismeAffilliation
            : false
        }
        onChange={onChangeOrganisme}
        disabled={disabled}
        hidden={hideNSSConjoint(values)}
        tooltip={
          "Saisir les 4 premiers chiffres du code organisme puis choisir le code dans la liste qui s’affiche. Ce n° d'organisme est indiqué sur l'attestation de Sécurité sociale sous l'appellation 'Organisme d'affiliation' ou 'Organisme de rattachement'."
        }
      />
      <MgcSelectField
        setFieldValue={setFieldValue}
        errors={errors}
        dirty={dirty}
        withOffset={true}
        onChange={onChangeSituation}
        table={situations}
        name="conjointSituationProfessionnelle"
        disabled={disabled}
        firstOption="- Situation professionnelle -"
        hidden={values["conjointCivilite"] === "no"}
        labelField={"libelle"}
        valueField={"code"}
        assusranceMalUnselected={
          !values.conjointRegimeAssurance &&
          values.conjointRattachement === "false"
        }
      />
      <MgcTextField
        name="conjointNumeroMatricule"
        errors={errors}
        dirty={dirty}
        disabled={disabled}
        hidden={
          values.conjointCivilite === "no" ||
          !values.conjointNumeroMatriculeToCheck
        }
        placeholder="Numéro de matricule"
      />
    </>
  )
}

export default AelConjoint
