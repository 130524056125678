import * as React from "react"
import MgcTextField from "./mgcTextField"
import { Field } from "formik"
import MgcCpVille from "./mgcCpVille"
import Axios from "axios"
import PropTypes from "prop-types"
import { MgcgestionTokenService } from "../services/mgcgestionTokenService"
import MaskedInput from "react-text-mask"
import { maskStreetNumber } from "../services/global/fieldMasks"
import "./aelAdresse.css"
import MgcSelectField from "./mgcSelectField"
import { hasOnlyNumbers } from "../utils/regexTests"

class AelAdresse extends React.Component {
 
  constructor(props) {
    super(props)
    this.state = {
      initValuePays: '',
      numeroLatinList: [],
      typeVoieList: [],
      paysList: [],
      filteredPaysList: [],
      aelCountryWarningHidden: true,
    }
    this.setAelCountryWarningHidden = this.setAelCountryWarningHidden.bind(this)
    this.handlePaysSelect = this.handlePaysSelect.bind(this)
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.values.adressePays &&
      prevState.paysList !== this.state.paysList
    ) {
      this.handlePaysSelect(this.props.values.adressePays)
      this.setState({ initValuePays: this.props.values.adressePays })
    }
    /*    if(this.props.values.enfant){
          console.log(this.props.values.enfant)
        }*/
  }
  componentDidMount() {
    const { values } = this.props
    const { adressePays } = values || {}
    let URL = `${process.env.GATSBY_MGCGESTION_API_URL}/public/numero-latins`
    /*    URL = "http://localhost:8080/api/public/numero-latins"*/
    Axios.get(URL).then(result => {
      this.setState({ numeroLatinList: result.data })
    })
    URL = `${process.env.GATSBY_MGCGESTION_API_URL}/public/type-voies?size=999&sort=libelle,asc`
    /*    URL = "http://localhost:8080/api/public/type-voies"*/
    Axios.get(URL).then(result => {
      this.setState({ typeVoieList: result.data })
    })
    URL = `${process.env.GATSBY_MGCGESTION_API_URL}/public/pays/autocomplete`
    /*    URL = "http://localhost:8080/api/public/pays/autocomplete"*/
    Axios.get(URL).then(result => {
      this.setState({ paysList: result.data })
      this.setState({ filteredPaysList: result.data.filter(p => { return !!p.cleEdeal; }) })
      if (adressePays) {
        this.handlePaysSelect(adressePays)
      }
    })
  }

  handlePaysSelect(value) {
    if (this.props.validCountryAel) {
      this.setAelCountryWarningHidden(value)
    }
  }
  setAelCountryWarningHidden(value) {
    // console.log(setAelCountryWarningHidden,)
    const { paysList } = this.state
    const { setFieldValue } = this.props
    if (value) {
      const validAel = paysList.some(p => p.libelle === value && p.ael === true)
      setFieldValue("countryValidAel", validAel)
      this.setState({
        aelCountryWarningHidden: validAel,
      })
    }
  }

  render() {
    const { errors, dirty, setFieldValue, values, disabled } = this.props
    return (
      <>
        <h3 className="offset-1">Adresse postale</h3>
        <h4 className="offset-1">
          (à laquelle vous recevrez votre carte de tiers payant)
        </h4>
        <MgcTextField
          dirty={dirty}
          errors={errors}
          name="appartement"
          placeholder={"N° Appt/Esc/Couloir/Etage"}
          disabled={disabled}
          maxLength={38}
          onChange={e => {
            if (hasOnlyNumbers(e.target.value)) {
              setFieldValue(e.target.name, e.target.value)
            }
          }}
        />
        <MgcTextField
          dirty={dirty}
          errors={errors}
          name="batiment"
          placeholder={"Entrée/Tour/Immeuble/Bât"}
          disabled={disabled}
          maxLength={38}
        />

        <div className="form-row">
          <div className="col-2 offset-1 form-group">
            <Field
              name="adresseNumero"
              render={({ field }) => {
                return (
                  <MaskedInput
                    mask={maskStreetNumber}
                    {...field}
                    className="form-control"
                    placeholder="N°"
                    guide={false}
                    id="adresseNumero"
                    disabled={disabled}
                  />
                )
              }}
            />
          </div>
          <div className="col-4 form-group">
            <Field
              name="adresseNumeroLatin"
              component="select"
              className="form-control"
              disabled={disabled}
            >
              <option value="">- N° latin -</option>
              {this.state.numeroLatinList.map(e => {
                return (
                  <option value={e.id} key={e.id}>
                    {e.libelle}
                  </option>
                )
              })}
            </Field>
          </div>
          <div className="col-4 form-group">
            <Field
              type="text"
              name="adresseComplementAlpha"
              className="form-control complementAlpha"
              placeholder="Complément alpha"
              maxlength="1"
              onChange={e => {
                setFieldValue('adresseComplementAlpha', e.target.value.toUpperCase())
              }}
              disabled={disabled}
            />
          </div>
          <div className="col-1">
            <span
              className={`${errors["adresseNumero"] ||
                  errors["adresseNumeroLatin"] ||
                  errors["adresseComplementAlpha"] ||
                  !dirty
                  ? "fa-times text-danger"
                  : "fa-check text-success"
                } fa-2x fa`}
            />
          </div>
        </div>

        <div className="form-row">
          <div className="col-4 form-group offset-1">
            <Field
              name="adresseTypeVoie"
              component="select"
              className="form-control"
              disabled={disabled}
            >
              <option value="">- Type de voie -</option>
              {this.state.typeVoieList.map(e => {
                return (
                  <option value={e.id} key={e.id}>
                    {e.libelle}
                  </option>
                )
              })}
            </Field>
          </div>
          <div className="col-6 form-group">
            <Field
              type="text"
              name="adresseVoie"
              className="form-control"
              placeholder="Nom de la voie"
              disabled={disabled}
              maxLength="25"
            />
          </div>
          <div className="col-1">
            <span
              className={`${errors["adresseTypeVoie"] || errors["adresseVoie"] || !dirty
                  ? "fa-times text-danger"
                  : "fa-check text-success"
                } fa-2x fa`}
            />
          </div>
        </div>

        <MgcTextField
          errors={errors}
          dirty={dirty}
          name="adresseLieuDit"
          placeholder="Lieu-dit"
          disabled={disabled}
          maxLength={38}
        />
        <MgcCpVille
          nameCP="adresseCodePostal"
          nameVille="adresseVille"
          errors={errors}
          dirty={dirty}
          setFieldValue={setFieldValue}
          values={values}
          placeholderCp="Code postal"
          disabled={disabled}
        />
        <MgcSelectField
          dirty={dirty}
          errors={errors}
          table={this.state.filteredPaysList}
          firstOption={"- Pays -"}
          labelField={"libelle"}
          valueField={"libelle"}
          name={"adressePays"}
          withOffset={true}
          onChange={this.handlePaysSelect}
          setFieldValue={setFieldValue}
          disabled={disabled}
        />
        {/*<Field type="hidden" name="countryValidAel" />*/}

        <div className="form-row">
          <div
            className="showCoche col-10 offset-1 alert alert-danger ael-adresse-pays-info"
            hidden={this.state.aelCountryWarningHidden}
          >
            L'adhésion en ligne est ouverte aux personnes résidant en France
            uniquement.
          </div>
        </div>
      </>
    )
  }
}

AelAdresse.propTypes = {
  errors: PropTypes.object,
  dirty: PropTypes.bool,
  setFieldValue: PropTypes.func,
  values: PropTypes.object,
  validCountryAel: PropTypes.bool,
}
export default AelAdresse
