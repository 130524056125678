import * as React from "react"
import { Field } from "formik"
import FileUpload from "./fileUpload"
import classNames from "classnames"

const AelStep4Upload = ({ title, label, namePiece, piece, hidden, errors, disabled }) => {

  const error = errors && errors[namePiece] && (errors[namePiece]["recto"] || errors[namePiece]["verso"])
  const checkerClassNames = classNames("fa fa-2x", {
    "fa-times text-danger": error,
    "fa-check text-success": !error,
  })
  return (
    <div className="row ael-upload" hidden={hidden}>
      {/*<h5 className="col-11 offset-1">{title}</h5>*/}
      <div className="col-11 offset-1">
        <div className="form-row display-block">
          <label>{title}</label>
          <br />
          <span>{label}</span>
        </div>
        <div className={"form-row"}>
          <div className="col-11 upload-piece">
            <div className="form-row">

              <div
                className={"col-12 col-md-6 text-center border-upload upload-col"}
              >
                <label className="">
                  <h5 className="text-uppercase">Recto</h5>
                </label>
                <Field 
                  name={`${namePiece}.recto`}
                  component={FileUpload}
                  disabled={disabled}
                  piece={piece && piece[namePiece] && piece[namePiece].recto}
                />
              </div>
              <div className={"col-12 col-md-6 text-center upload-col"}>
                <label>
                  <h5 className="text-uppercase">Verso</h5>
                </label>
                <Field 
                  name={`${namePiece}.verso`}
                  component={FileUpload}
                  disabled={disabled}
                  piece={piece && piece[namePiece] && piece[namePiece].verso}
                />
              </div>



            </div>



            </div>
          <div className="col-1">
            <span className={checkerClassNames} />
          </div>
        </div>

      </div>
    </div>
  )
}

export default AelStep4Upload
