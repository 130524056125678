import * as React from "react"
import QuotationFormula from "./quotationFormula"

const AelPropositions = ({ propositions, onSelect, disabled }) => {
  return (
    <>
      <QuotationFormula formules={propositions} button={true} onClickButton={onSelect} disabled={disabled}/>
    </>
  )
}

export default AelPropositions
