import * as React from "react"
import AelWizard from "./aelWizard"
import { Field } from "formik"
import Tooltip from "react-bootstrap/Tooltip"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import MgcTextField from "./mgcTextField"
import MgcSelectField from "./mgcSelectField"
import Axios from "axios"
import MgcAutoComplete from "./mgcAutocomplete"
import MgcPasswordField from "./mgcPasswordField"
import MgcEmailField from "./mgcEmailField"
import AelAdresse from "./aelAdresse"
import AelAgentTerritorial from "./aelAgentTerritorial"
import AelTns from "./aelTns"
import AelPersonne from "./aelPersonne"
import AelConjoint from "./aelConjoint"
import AelEnfants from "./aelEnfants"
import MgcNssField from "./mgcNssField"
import informationIcon from "../../src/images/icon-information.png"
import { MgcgestionTokenService } from "../services/mgcgestionTokenService"
import GAnalyticsService from "../services/gAnalyticsService"
import MgcPhoneField from "./mgcPhoneField"

class AelStep1 extends React.Component {
 
  constructor(props) {
    super(props)
    this.state = {
      regimeAssuranceList: [],
      allSituationProfessionelles: [],
      situationProfessionellesList: [],
      adherentSituationProfessionnellesFiltered: [],
      conjointSituationProfessionnellesFiltered: [],
      organismeRattachementList: [],
      situationFamillialeList: [],
      adherentWithmattricule: false,
      adherentTns: false,
      adherentAt: false,
      prelevementSalairePension: false,
      initValueOrganismeAffilliation: "",
      fromDevis: {}
    }
    this.handleOrganismeChange = this.handleOrganismeChange.bind(this)
    this.handleAdherentRegimeAssuranceChange = this.handleAdherentRegimeAssuranceChange.bind(
      this
    )
    this.handleConjointRegimeAssuranceChange = this.handleConjointRegimeAssuranceChange.bind(
      this
    )
    this.handleAdherentSituationprofessionelleChange = this.handleAdherentSituationprofessionelleChange.bind(
      this
    )
    this.handleConjointSituationprofessionelleChange = this.handleConjointSituationprofessionelleChange.bind(
      this
    )
  }

  componentDidMount() {
    GAnalyticsService.sendPageviw("/mgc-adhesion-etape1")
    if (this.props.values.adherentOrganismeAffilliation) {
      let code = this.props.values.adherentOrganismeAffilliation.split("-")
      if (code[1]) {
        code = code[1].substring(1, code[1].length)
        this.handleOrganismeChange(code)
        this.setState({
          initValueOrganismeAffilliation: this.props.values
            .adherentOrganismeAffilliation,
        })
      }
    }

    if (sessionStorage.getItem("quotToAel")) {
      const fromDevis = JSON.parse(sessionStorage.getItem("quotToAel"))
      this.setState({ fromDevis })
    }

    const { validateForm, values, setFieldValue } = this.props
    const screenSize = window.innerWidth
    if (screenSize < 481) {
      window.scrollTo(0, 200)
    }
    else if (screenSize < 769) {
      window.scrollTo(0, 225)
    } else {
      window.scrollTo(0, 0)
    }
    let URL = `${process.env.GATSBY_MGCGESTION_API_URL}/public/regime-assurances/actif`
    /*        URL = "http://localhost:8080/api/public/regime-assurances/actif"*/
    Axios.get(URL)
      .then(result => {
        this.setState({ regimeAssuranceList: result.data })
      })
      .then(() => {
        URL = `${process.env.GATSBY_MGCGESTION_API_URL
          }/public/situation-professionnelles/actives`
        /*                  URL = "http://localhost:8080/api/public/situation-professionnelles/actives"*/
        Axios.get(URL).then(result => {
          this.setState({ allSituationProfessionelles: result.data })
          let situationPro = []
          this.state.regimeAssuranceList.forEach(ra => {
            situationPro[ra.code] = []
            result.data.forEach(sp => {
              sp.regimeAssurances.forEach(ra2 => {
                if (ra2.code === ra.code) {
                  situationPro[ra2.code].push(sp)
                }
              })
            })
          })
          this.setState({
            situationProfessionellesList: situationPro,
            conjointSituationProfessionnellesFiltered: result.data,
          })
          if (values.adherentRegimeAssurance) {
            this.handleAdherentRegimeAssuranceChange(
              values.adherentRegimeAssurance
            )
            if (values.adherentSituationProfessionelle) {
              this.handleAdherentSituationprofessionelleChange(
                values.adherentSituationProfessionelle
              )
            }
          }
        })
      })
    URL = `${process.env.GATSBY_MGCGESTION_API_URL}/public/situation-familiales/actif`
    /*        URL = "http://localhost:8080/api/public/situation-familiales/actif"*/
    Axios.get(URL).then(result => {
      this.setState({ situationFamillialeList: result.data })
    })
    if (!!values.email) {
      setFieldValue('confirmEmail', values.email)
    }
    validateForm()
  }

  handleOrganismeChange(value) {
    if (!value) {
      this.setState({ organismeRattachementList: [] })
    } else if (value.length && value.length >= 4) {
      let URL = `${process.env.GATSBY_MGCGESTION_API_URL
        }/public/organisme-affiliations/autocomplete/${value}`
      /*             URL ="http://localhost:8080/api/public/organisme-affiliations/autocomplete/" + value*/
      Axios.get(URL).then(
        result => {
          this.setState({
            organismeRattachementList: result.data.map(or => {
              return {
                display: `${or.nomCaisse} - ${or.fullCode}`,
                ...or,
              }
            }),
          })
        }
      )
    }
  }

  handleAdherentRegimeAssuranceChange(value) {
    const { setFieldValue } = this.props
    const { fromDevis, situationProfessionellesList } = this.state
    const { adherentSituationProfessionelle } = fromDevis || {}
    const filteredSituationProList = situationProfessionellesList[value]
    this.setState({
      adherentSituationProfessionnellesFiltered: filteredSituationProList,
    })
    this.handleAdherentSituationProfessionnelleFromDevis(setFieldValue, adherentSituationProfessionelle, filteredSituationProList)
  }

  handleAdherentSituationProfessionnelleFromDevis(setFieldValue, adherentSituationProfessionelle, filteredSituationProList) {
    if (!adherentSituationProfessionelle || !this.findSituationProInArray(filteredSituationProList, adherentSituationProfessionelle || null)) {
      setFieldValue("adherentSituationProfessionelle", null)
    } else {
      console.log('adherentSituationProfessionnelle', adherentSituationProfessionelle)
      this.handleAdherentSituationprofessionelleChange(adherentSituationProfessionelle, filteredSituationProList)
    }
  }

  findSituationProInArray(array, code) {
    return array.find((sp) => {
      return sp.code === code;
    })
  }

  handleAdherentSituationprofessionelleChange(value, filteredSpList) {
    const { setFieldValue } = this.props
    const filteredList = filteredSpList || this.state.adherentSituationProfessionnellesFiltered
    const sp = filteredList.find(
      spf => value === spf.code
    )
    if (sp) {
      this.setState({
        adherentWithMatricule: sp.avecMatricule,
        adherentTns: sp.tns || sp.tnsExa,
        adherentAt: sp.agentTerritorial,
        prelevementSalairePension: sp.prelevementSalairePension,
      })
      setFieldValue("adherentAgentTerritorialToCheck", sp.agentTerritorial)
      setFieldValue(
        "adherentPrelevementSalairePension",
        sp.prelevementSalairePension
      )
      setFieldValue(
        "coordonneesBancaires.prelevementSalairePension",
        sp.prelevementSalairePension
      )
      setFieldValue("coordonneesBancaires.isTns", sp.tns || sp.tnsExa)
      setFieldValue("isTns", sp.tns || sp.tnsExa)
      setFieldValue("adherentTnsToCheck", sp.tns || sp.tnsExa)
    }

    setFieldValue("adherentSituationProfessionelle", sp ? sp.code : null)
    setFieldValue(
      "adherentNumeroMatriculeToCheck",
      sp ? sp.avecMatricule : false
    )
  }

  handleConjointSituationprofessionelleChange(value, filteredSituationProList) {
    const { setFieldValue, values } = this.props
    const { conjointSituationProfessionnellesFiltered } = this.state
    const filteredSpList = filteredSituationProList || conjointSituationProfessionnellesFiltered
    const sp = filteredSpList.find(
      spf => parseInt(value) === parseInt(spf.id)
    )
    if (sp) {
      this.setState({
        conjointWithMatricule: sp.avecMatricule,
        adherentTns: sp.tns || sp.tnsExa,
        adherentAt: sp.agentTerritorial,
      })
    }

    setFieldValue("conjointSituationProfessionelle", sp ? sp.code : null)
    setFieldValue(
      "conjointNumeroMatriculeToCheck",
      this.checkConjointNumeroMatricule(sp, values)
    )
  }

  checkConjointNumeroMatricule(sp, values) {
    return sp && sp.avecMatricule && !values.conjointRattachement
  }

  handleConjointRegimeAssuranceChange(value) {
    const { setFieldValue } = this.props
    const { situationProfessionellesList, fromDevis } = this.state
    const filteredSituationProList = situationProfessionellesList[value]
    const { conjointSituationProfessionelle } = fromDevis || {}

    this.setState({
      conjointSituationProfessionnellesFiltered: filteredSituationProList
    })

    // if (!conjointSituationProfessionelle || !this.findSituationProInArray(conjointSituationProfessionelle[value], conjointSituationProfessionelle || null)) {
    //   setFieldValue("conjointSituationProfessionelle", null)
    // }
    this.handleConjointSituationProfessionnelleFromDevis(setFieldValue, conjointSituationProfessionelle, filteredSituationProList)

  }

  handleConjointSituationProfessionnelleFromDevis(setFieldValue, conjointSituationProfessionelle, filteredSituationProList) {
    if (!conjointSituationProfessionelle || !this.findSituationProInArray(filteredSituationProList, conjointSituationProfessionelle || null)) {
      setFieldValue("conjointSituationProfessionelle", null)
    } else {
      console.log('conjointSituationProfessionelle', conjointSituationProfessionelle)
      this.handleConjointSituationprofessionelleChange(conjointSituationProfessionelle, filteredSituationProList)
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.values &&
      this.state.situationProfessionellesList !==
      prevState.situationProfessionellesList

    ) {
      this.handleAdherentRegimeAssuranceChange(
        this.props.values.adherentRegimeAssurance
      )
      if (this.props.values.conjointRegimeAssurance) {
        this.handleConjointRegimeAssuranceChange(
          this.props.values.conjointRegimeAssurance
        )
      }
      if (this.props.values.conjointSituationProfessionnelle) {
        this.handleConjointSituationprofessionelleChange(
          this.props.values.conjointSituationProfessionnelle
        )
      }
    }
    if (
      this.props.values &&
      this.props.values.adherentOrganismeAffilliation !==
      prevProps.values.adherentOrganismeAffilliation
    ) {
      let code = this.props.values.adherentOrganismeAffilliation.split("-")
      if (code[1]) {
        code = code[1].substring(1, code[1].length)
        this.handleOrganismeChange(code)
        this.setState({
          initValueOrganismeAffilliation: this.props.values
            .adherentOrganismeAffilliation,
        })
      }
    }
  }
  resetInitValue = () => {
    this.setState({ initValueOrganismeAffilliation: null })
  }
  isErrorPasswordFormat = errors => {
    return errors.password && errors.password === "format"
  }

  render() {
    const {
      errors,
      dirty,
      values,
      touched,
      setFieldValue,
      rollback,
      disabled,
      done,
      handleChange,
      resetErrorsAfterSubmitWithErrors
    } = this.props

    const emailDisabled = done

    return (
      <AelWizard.Step>
        <div className="title-btn-rollback-ael form-row mb-2">
          <div className="col-1 text-right">
            <OverlayTrigger
              overlay={
                <Tooltip id="title-tooltip" placement={"right"}>
                  <p>
                    Nous attirons votre attention sur le fait que la fourniture
                    d'une information complète et sincère aux questions posées
                    par le biais du formulaire à suivre est une condition
                    indispensable à la délivrance d'un conseil vous permettant
                    de prendre une décision en connaissance de cause. Le recueil
                    des informations est effectué dans votre intérêt et ce dans
                    le but de vous fournir un conseil cohérent avec vos besoins
                    et exigences.
                      </p>
                </Tooltip>
              }
            >
              <img src={informationIcon} className="icon-info-ael" />
            </OverlayTrigger>
          </div>
          <div className="title-rollback-ael">
            <h2 className={"step-title"}>Personne(s) à couvrir</h2>
          </div>

          <button
            type="button"
            className="btn-rollback-ael"
            onClick={() => rollback(0)}
            hidden={!done}
          >
            {disabled ? "Modifier" : "Consulter"}
          </button>
        </div>

        <div className="bloc-form bloc-adherent">
          {/*<MgcEckert errors={errors} dirty={dirty} values={values} touched={touched} setFieldValue={setFieldValue} prefix="adherent"/>*/}
          <AelPersonne
            prefix="adherent"
            setFieldValue={setFieldValue}
            errors={errors}
            dirty={dirty}
            values={values}
            title={"Adhérent principal"}
            touched={touched}
            withNo={false}
            disabled={disabled}
          />

          <MgcSelectField
            dirty={dirty}
            errors={errors}
            table={this.state.regimeAssuranceList}
            firstOption={"- Régime d'assurance maladie -"}
            labelField={"libelle"}
            valueField={"code"}
            name={"adherentRegimeAssurance"}
            withOffset={true}
            onChange={this.handleAdherentRegimeAssuranceChange}
            setFieldValue={setFieldValue}
            disabled={disabled}
          />

          <MgcNssField
            dirty={dirty}
            setFieldValue={setFieldValue}
            errors={errors}
            name="adherentNumeroSecuriteSociale"
            placeholder="Numéro de sécurité sociale"
            disabled={disabled}
          />

          <div className="form-row">
            <div className="col-11 offset-1 checkbox">
              <label>
                <Field
                  name="adherentRattache"
                  type="checkbox"
                  component="input"
                  disabled={disabled}
                />
                <span className="cr">
                  <i className="cr-icon fa fa-check" />
                </span>
                  Je suis rattaché(e) à mon conjoint ou à un parent en qualité
                  d'ayant droit à la Sécurité sociale
                </label>
            </div>
          </div>

          <MgcAutoComplete
            onChange={this.handleOrganismeChange}
            errors={errors}
            dirty={dirty}
            name="adherentOrganismeAffilliation"
            labelField="display"
            setFieldValue={setFieldValue}
            items={this.state.organismeRattachementList}
            placeholder="Code organisme de rattachement"
            values={values}
            initValue={this.state.initValueOrganismeAffilliation}
            resetInitValue={this.resetInitValue}
            disabled={disabled}
            tooltip={
              "Saisir les 4 premiers chiffres du code organisme puis choisir le code dans la liste qui s’affiche. Ce n° d'organisme est indiqué sur l'attestation de Sécurité sociale sous l'appellation 'Organisme d'affiliation' ou 'Organisme de rattachement'."
            }
          />

          <MgcSelectField
            labelField="libelle"
            valueField="code"
            dirty={dirty}
            errors={errors}
            name="adherentSituationFamilliale"
            firstOption="- Situation familiale -"
            table={this.state.situationFamillialeList}
            withOffset={true}
            setFieldValue={setFieldValue}
            disabled={disabled}
          />

          <MgcSelectField
            labelField="libelle"
            valueField="code"
            dirty={dirty}
            errors={errors}
            name={"adherentSituationProfessionelle"}
            withOffset={true}
            table={this.state.adherentSituationProfessionnellesFiltered}
            firstOption="- Situation professionnelle -"
            setFieldValue={setFieldValue}
            onChange={this.handleAdherentSituationprofessionelleChange}
            disabled={disabled}
            assusranceMalUnselected={
              !values.adherentRegimeAssurance
            }
          />

          <MgcTextField
            name="adherentNumeroMatricule"
            errors={errors}
            dirty={dirty}
            hidden={!this.state.adherentWithMatricule}
            placeholder="Numéro de matricule"
            disabled={disabled}
          />

          <AelAgentTerritorial
            errors={errors}
            dirty={dirty}
            hidden={!this.state.adherentAt}
            values={values}
            touched={touched}
            disabled={disabled}
            setFieldValue={setFieldValue}
          />
          <AelTns
            touched={touched}
            errors={errors}
            dirty={dirty}
            hidden={!this.state.adherentTns}
            values={values}
            disabled={disabled}
            setFieldValue={setFieldValue}
          />

          <MgcEmailField
            errors={errors}
            dirty={dirty}
            tooltip="Votre adresse e-mail servira à la création de votre espace personnel sécurisé et à l'envoi de la documentation commerciale, précontractuelle et contractuelle."
            name="email"
            placeholder="Adresse e-mail"
            disabled={emailDisabled}
          />
          <MgcEmailField
            errors={errors}
            dirty={dirty}
            name="confirmEmail"
            placeholder="Confirmation de l'adresse e-mail"
            disabled={emailDisabled}
          />
          <MgcPasswordField
            errors={errors}
            dirty={dirty}
            name="password"
            placeholder="Choisir un mot de passe"
            disabled={disabled}
          />
          <div
            className="showCoche col-10 offset-1"
            id="ael0contentCoche"
            hidden={!this.isErrorPasswordFormat(errors)}
          >
            Votre mot de passe doit être composé d’au moins 8 caractères, contenant au moins 1 majuscule, 1 chiffre et 1 caractère spécial (! @ # $ % ^ & * . _ -).
            </div>
          <MgcPasswordField
            errors={errors}
            dirty={dirty}
            name="passwordConfirmation"
            placeholder="Confirmer le mot de passe"
            disabled={disabled}
          />
          <MgcPhoneField
            dirty={dirty}
            errors={errors}
            tooltip="Vous avez la possibilité de vous inscrire gratuitement sur la liste d’opposition au démarchage téléphonique. Pour vous inscrire : www.bloctel.gouv.fr"
            name="portable"
            label="Téléphone portable"
            disabled={disabled}
            setFieldValue={setFieldValue}
            values={values}
          />
          <div className="form-row">
            <div className="showCoche col-10 offset-1" id="ael0contentCoche">
              Sur ce numéro, vous recevrez, par SMS, le code de sécurité vous
              permettant de signer électroniquement vos documents d’adhésion. Un
              conseiller MGC est susceptible de vous contacter à ce numéro pour
              vous accompagner dans votre souscription en ligne.
              </div>
          </div>
          <MgcPhoneField
            dirty={dirty}
            errors={errors}
            tooltip="Vous avez la possibilité de vous inscrire gratuitement sur la liste d’opposition au démarchage téléphonique. Pour vous inscrire : www.bloctel.gouv.fr"
            name="fixe"
            label="Autre téléphone"
            disabled={disabled}
            setFieldValue={setFieldValue}
            values={values}
          />

          <AelAdresse
            errors={errors}
            dirty={dirty}
            setFieldValue={setFieldValue}
            values={values}
            validCountryAel={true}
            disabled={disabled}
          />
        </div>

        <AelConjoint
          withNo={true}
          touched={touched}
          values={values}
          dirty={dirty}
          title="Conjoint"
          errors={errors}
          setFieldValue={setFieldValue}
          prefix="conjoint"
          onChangeSituation={this.handleConjointSituationprofessionelleChange}
          situations={this.state.conjointSituationProfessionnellesFiltered}
          organismes={this.state.organismeRattachementList}
          regimes={this.state.regimeAssuranceList}
          onChangeOrganisme={this.handleOrganismeChange}
          onChangeRegime={this.handleConjointRegimeAssuranceChange}
          disabled={disabled}
        />

        <AelEnfants
          setFieldValue={setFieldValue}
          touched={touched}
          regimes={this.state.regimeAssuranceList}
          dirty={dirty}
          errors={errors}
          organismes={this.state.organismeRattachementList}
          values={values}
          handleOrganismeChange={this.handleOrganismeChange}
          disabled={disabled}
        />
      </AelWizard.Step>
    )
  }
}

export default AelStep1
