import MgcRadioToggleButtonGroup from "./mgcRadioToggleButtonGroup"
import { Field } from "formik"
import MgcRadioToggleButton from "./mgcRadioToggleButton"
import MgcTextField from "./mgcTextField"
import * as React from "react"
import PropTypes from 'prop-types'
import Layout from "./layout"

class AelBankAccount extends React.Component {
  constructor(props) {
    super(props)
    this.displayWarningAccount = this.displayWarningAccount.bind(this);
    this.displayCompteProfessionnel = this.displayCompteProfessionnel.bind(this);
    this.disableCompteProfessionnel = this.disableCompteProfessionnel.bind(this);
    this.displayWarningCompteProfessionnel = this.displayWarningCompteProfessionnel.bind(this)
  }

  componentDidMount() {
    const {disableCompteProfessionnel, setFieldValue, values, nameCompteProfessionnel} = this.props;
    const {coordonneesBancaires} = values;
    const {isTns} = coordonneesBancaires || {};
    if (isTns && disableCompteProfessionnel) {
      setFieldValue(`coordonneesBancaires.${nameCompteProfessionnel}`, false);
    }
  }

  displayWarningAccount() {
    const { nameTitulaire, values } = this.props
    const { coordonneesBancaires } = values
    return coordonneesBancaires && coordonneesBancaires[`${nameTitulaire}`] === 'false'
  }

  displayCompteProfessionnel() {
    const {coordonneesBancaires} = this.props.values;
    const {isTns} = coordonneesBancaires || {};
    return isTns;
  }
  displayWarningCompteProfessionnel() {
    return this.disableCompteProfessionnel();
  }
  disableCompteProfessionnel() {
    // return true;
    const {disableCompteProfessionnel, disabled} = this.props;
    return disabled || (disableCompteProfessionnel && this.displayCompteProfessionnel());
  }


  render() {
    const {
      hidden,
      labelTitulaire,
      values,
      dirty,
      errors,
      touched,
      nameTitulaire,
      nameCompteProfessionnel,
      nameBic,
      nameIban,
      disabled
    } = this.props
    return (
      <div hidden={hidden}>
        <div className="form-row">
          <label className="col-11 offset-1">{labelTitulaire}</label>
        </div>
        <MgcRadioToggleButtonGroup
          id={`coordonneesBancaires.${nameTitulaire}`}
          value={values["coordonneesBancaires"]}
          error={errors["coordonneesBancaires"] && errors["coordonneesBancaires"][nameTitulaire]}
          touched={touched["coordonneesBancaires"]}
        >
          <Field
            component={MgcRadioToggleButton}
            name={`coordonneesBancaires.${nameTitulaire}`}
            id="true"
            label={"Oui"}
            disabled={disabled}
          />
          <Field
            component={MgcRadioToggleButton}
            name={`coordonneesBancaires.${nameTitulaire}`}
            id="false"
            label={"Non"}
            disabled={disabled}
          />
        </MgcRadioToggleButtonGroup>
        <MgcRadioToggleButtonGroup
          id={`coordonneesBancaires.${nameCompteProfessionnel}`}
          value={values["coordonneesBancaires"]}
          error={errors["coordonneesBancaires"] && errors["coordonneesBancaires"][nameCompteProfessionnel]}
          touched={touched["coordonneesBancaires"]}
          hidden={!this.displayCompteProfessionnel()}

        >
          <Field
            component={MgcRadioToggleButton}
            name={`coordonneesBancaires.${nameCompteProfessionnel}`}
            id="true"
            label={"Compte professionnel"}
            disabled={this.disableCompteProfessionnel()}
          />
          <Field
            component={MgcRadioToggleButton}
            name={`coordonneesBancaires.${nameCompteProfessionnel}`}
            id="false"
            label={"Compte personnel"}
            disabled={this.disableCompteProfessionnel()}
          />
        </MgcRadioToggleButtonGroup>
        <div className="form-row">
          <div className="col-10 offset-1" hidden={!this.displayWarningCompteProfessionnel()}>
            <p>Vos remboursements peuvent uniquement être versés sur votre compte personnel.</p>
          </div>
        </div>
        <div className="form-row">
          <div
            className="showCoche col-10 offset-1"
            id="ael0contentCoche"
            style={{ marginBottom: "10px" }}
            hidden={!this.displayWarningAccount()}
          >
            <p>
              Vous souhaitez que vos cotisations soient prélevées sur le compte
              d’un tiers ou que vos remboursements soient versés sur le compte
              bancaire d’un tiers. Dans cette situation, l’adhésion en ligne
              n’est pas possible.
            </p>
            <p>
              Pour adhérer, vous devez envoyer à la MGC votre{" "}
              <a
                href="/mutuelle-sante/adhesion-courrier"
                target="_blank"
              >
                bulletin d’adhésion par courrier
              </a>{" "}
              accompagné des pièces justificatives et de l’autorisation de
              compte tiers.{" "}
              <a
                href="/mutuelle-sante/adhesion-courrier"
                target="_blank"
              >
                Télécharger l’autorisation de compte tiers.
              </a>
            </p>
          </div>
        </div>
        <MgcTextField
          dirty={dirty}
          errors={errors["coordonneesBancaires"] || {}}
          prefix={"coordonneesBancaires."}
          name={nameBic}
          placeholder="BIC"
          hidden={this.displayWarningAccount()}
          disabled={disabled}
        />
        <MgcTextField
          dirty={dirty}
          errors={errors["coordonneesBancaires"] || {}}
          prefix={`coordonneesBancaires.`}
          name={nameIban}
          placeholder="IBAN"
          hidden={this.displayWarningAccount()}
          disabled={disabled}
        />
      </div>
    )
  }
}

AelBankAccount.propTypes = {
  hidden: PropTypes.bool,
  labelTitulaire: PropTypes.string,
  values: PropTypes.object,
  dirty: PropTypes.object,
  errors: PropTypes.object,
  touched: PropTypes.object,
  nameTitulaire: PropTypes.string,
  nameCompteProfessionnel: PropTypes.string,
  nameBic: PropTypes.string,
  nameIban: PropTypes.string,
  disableCompteProfessionnel: PropTypes.bool,
  setFieldValue: PropTypes.func
}

export default AelBankAccount
