import React, { useState } from "react"
import Layout from "../components/layout"
import Ael from "../components/ael"
import withLocation from "../components/withLocation"

const AelTest = ({ search, pageContext: { canonicalUrl: canonical } }) => {

  const [aelFinalised, setAelFinalised] = useState(false)

  const finalizeAel = () => {
    setAelFinalised(true)
  }
  return (
    <Layout
      canonical={canonical}
      keywordsSeo={["Adhésion en ligne", '']}
      titleSeo={'Adhésion en ligne : simple et rapide'}
      description={'Adhérez en ligne (particulier, cheminot, agent territorial, TNS...) à la mutuelle santé MGC, c\'est immédiat, sécurisé et aucun papier à nous envoyer !'}
    >

      <section className="pageHeader">
        <div className="pageHeaderContainer">
          <nav>
            <ul className="breadcrumb">
              <li className="breadcrumbItem">
                <a href="/" className="breadcrumbItemLink breadcrumbItemLinkHome">
                  <i className="icon-mgc" />
                </a>
              </li>
              <li className="breadcrumbItem">
              <span className="breadcrumbItemLink breadcrumbItemLinkMiddle">
                Adhésion en ligne
              </span>
              </li>
            </ul>
          </nav>
        </div>
      </section>
      <section className="pageHeader callback">
        <div>
          <h1 className="pageHeaderTitle">{aelFinalised ? "Adhésion finalisée": "Adhésion en ligne MGC"}</h1>
        </div>
      </section>
      <Ael search={search} onAelFinalized={finalizeAel} />
    </Layout>
  )
}
export default withLocation(AelTest)
