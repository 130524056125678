import React from "react"
import PropTypes from "prop-types"
import { FieldArray } from "formik"
import AelEnfant from "./aelEnfant"

class AelEnfants extends React.Component {
  constructor(props) {
    super(props)
    this.handleDateNaissanceChange = this.handleDateNaissanceChange.bind(this)
  }

  handleDateNaissanceChange(value) {

    const { values, setFieldValue } = this.props
    const twins = values.enfants
      .map((e, idx) => {
        return { ...e, index: idx }
      })
      .filter(e => e.DateNaissance === value)
    if (twins.length > 1) {
      twins.forEach(t => {
        setFieldValue(`enfants[${t.index}].showRangGemellaire`, true)
      })
    }
  }

  render() {
    const {
      values,
      errors,
      dirty,
      setFieldValue,
      touched,
      regimes,
      organismes,
      handleOrganismeChange,
      disabled
    } = this.props
    return (
      <FieldArray
        name="enfants"
        render={arrayHelpers => (
          <div className="bloc-form bloc-enfants">
            <h3 className="offset-1">Enfant(s)</h3>

            {values.enfants.map((enfant, index) => (
              <div key={index} className="bloc-enfant">
                <div className="col-12 text-right">
                  <button
                    type="button"
                    onClick={() => arrayHelpers.remove(index)}
                    className="btn btn-light"
                  >
                    <span className="fa fa-times" />
                  </button>
                </div>

                <AelEnfant
                  index={index}
                  values={values}
                  organismes={organismes}
                  errors={errors}
                  dirty={dirty}
                  regimes={regimes}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  prefix={`enfants[${index}].`}
                  handleDateChange={this.handleDateNaissanceChange}
                  handleOrganismeChange={handleOrganismeChange}
                  key={index}
                  disabled={disabled}
                />
              </div>
            ))}
            <div className="col-11 offset-1">
              <div className="input-group">
                <button
                  type="button"
                  className="btn btn-light"
                  onClick={() => arrayHelpers.push({})}
                  disabled={disabled}
                >
                  <span className="fa-2x fa fa-plus" />{" "}
                </button>{" "}
                <span className="btn-align">Ajouter un enfant</span>
              </div>
            </div>
          </div>
        )}
      />
    )
  }
}

AelEnfants.propTypes = {
  errors: PropTypes.object,
  dirty: PropTypes.object,
  setFieldValue: PropTypes.func,
  handleOrganismeChange: PropTypes.func,
  values: PropTypes.object,
  touched: PropTypes.object,
  regimes: PropTypes.array,
  organismes: PropTypes.array,
}

export default AelEnfants
