import * as React from "react"
import AelWizard from "./aelWizard"
import { Field } from "formik"
import AelBankAccount from "./aelBankAccount"
import MgcRadioGroup from "./mgcRadioGroup"
import MgcRadio from "./mgcRadio"
import GAnalyticsService from "../services/gAnalyticsService"

class AelStep3 extends React.Component {
    constructor(props) {
        super(props)
        this.displayCompteRemboursement = this.displayCompteRemboursement.bind(this)
        this.disabledIdentiqueDebitRemboursement = this.disabledIdentiqueDebitRemboursement.bind(this)
        this.forceDisplayRemboursement = this.forceDisplayRemboursement.bind(this)

    }

    componentDidMount() {
        const screenSize = window.innerWidth
        if(screenSize< 481){

            window.scrollTo(0, 200)
        }
        else if(screenSize< 769){
            window.scrollTo(0, 225)
        }else{
            window.scrollTo(0, 0)
        }
        const { validateForm, values, setFieldValue } = this.props
        const {
            coordonneesBancaires, codeGamme
        } = values
        const identiqueDebitRemboursement = !coordonneesBancaires.identiqueDebitRemboursement ? !coordonneesBancaires.prelevementSalairePension : coordonneesBancaires.identiqueDebitRemboursement
        setFieldValue('coordonneesBancaires.identiqueDebitRemboursement', identiqueDebitRemboursement);
        validateForm();
        if (codeGamme) {
            GAnalyticsService.sendPageviw(`/mgc-adhesion-${codeGamme}-etape3`)
        }
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        const {values} = this.props
        const {coordonneesBancaires} = values || {}
        const {compteProfessionnelDebit} = coordonneesBancaires || {}

        const {values: prevValues} = prevProps
        const {coordonneesBancaires: prevCoordonneesBancaires} = prevValues || {}
        const {compteProfessionnelDebit: prevCompteProfessionnelDebit} = prevCoordonneesBancaires || {}

        if (compteProfessionnelDebit != prevCompteProfessionnelDebit && (compteProfessionnelDebit === true || compteProfessionnelDebit === "true")) {
            this.forceDisplayRemboursement()
        }
    }

    displayCompteRemboursement() {
        const {
            coordonneesBancaires,
            adherentPrelevementSalairePension,
        } = this.props.values
        const { identiqueDebitRemboursement, prelevementSalairePension, prelevementSepa } = coordonneesBancaires || {};
        const identique = identiqueDebitRemboursement+''
        return identique === "false" || (prelevementSalairePension && !prelevementSepa);
    }

    displayCompteDebit() {
        const {
            coordonneesBancaires,
        } = this.props.values
        const { prelevementSepa, prelevementSalairePension } = coordonneesBancaires || {};
        return !prelevementSalairePension || (prelevementSalairePension && prelevementSepa);
    }

    forceDisplayRemboursement() {
        const { setFieldValue } = this.props
        setFieldValue("coordonneesBancaires.identiqueDebitRemboursement", false)
    }

    disabledIdentiqueDebitRemboursement(){
        const {values} = this.props
        const {coordonneesBancaires} = values || {}
        const {compteProfessionnelDebit} = coordonneesBancaires || {}
        return compteProfessionnelDebit === true || compteProfessionnelDebit === "true";

    }
    render() {
        const { errors, dirty, values, touched, setFieldValue , rollback, disabled, done} = this.props
        const { adherentPrelevementSalairePension } = values

        return (
            <AelWizard.Step>
                <div className='form-row row'>
                    <div className="title-rollback-ael  offset-1">
                        <h2 className={"step-title"}>Coordonnées bancaires</h2>
                    </div>
                    <button
                        type="button"
                        className="btn-rollback-ael"
                        onClick={()=>rollback(2)}
                        hidden={!done}
                    >
                        {disabled ? 'Modifier' : 'Consulter'}
                    </button>
                </div>
                <div className="row">
                    <h5 className="col-11 offset-1">Paiement des cotisations</h5>
                </div>
                <div className="row" hidden={!adherentPrelevementSalairePension}>
                    <p className="col-11 offset-1">
                        Paiement des cotisations par prélevement mensuel sur mon salaire ou
                        ma pension SNCF
                    </p>
                </div>
                <div className="row" hidden={!adherentPrelevementSalairePension}>
                    <div className="col-11 checkbox offset-1">
                        <label>
                            <Field name="coordonneesBancaires.prelevementSepa" type="checkbox" component="input" disabled={disabled} />
                            <span className="cr"><i className="cr-icon fa fa-check"/></span>
                            je préfère être prélevé sur mon compte bancaire par mandat SEPA, le
                            7 de chaque mois
                        </label>

                    </div>
                </div>

                <AelBankAccount
                    errors={errors}
                    values={values}
                    touched={touched}
                    dirty={dirty}
                    labelTitulaire="Je suis titulaire du compte à débiter"
                    nameBic="bicDebit"
                    nameIban="ibanDebit"
                    nameTitulaire="titulaireDebit"
                    nameCompteProfessionnel="compteProfessionnelDebit"
                    hidden={!this.displayCompteDebit()}
                    setFieldValue={setFieldValue}
                    disableCompteProfessionnel={false}
                    disabled={disabled}
                />
                <div>
                    <div className="form-row" hidden={!this.displayCompteDebit()}>
                        <label className="col-11 offset-1">
                            Mes remboursements et ceux de mes bénéficiaires seront versés sur
                            :
                        </label>
                    </div>
                    <MgcRadioGroup
                        touched={touched}
                        value={
                            values["coordonneesBancaires"] &&
                            values["coordonneesBancaires"]["identiqueDebitRemboursement"]
                        }
                        error={
                            errors["coordonneesBancaires"] &&
                            errors["coordonneesBancaires"]["identiqueDebitRemboursement"]
                        }
                        id="coordonneesBancaires.identiqueDebitRemboursement"
                        hidden={!this.displayCompteDebit()}
                    >
                        <Field
                            component={MgcRadio}
                            name="coordonneesBancaires.identiqueDebitRemboursement"
                            id="true"
                            label="le même compte que pour les prélèvements"
                            disabled={disabled || this.disabledIdentiqueDebitRemboursement()}
                        />
                        <Field
                            component={MgcRadio}
                            name="coordonneesBancaires.identiqueDebitRemboursement"
                            id="false"
                            label="un autre compte bancaire"
                            disabled={disabled || this.disabledIdentiqueDebitRemboursement()}
                        />
                    </MgcRadioGroup>
                </div>
                <div className="row" hidden={!this.displayCompteRemboursement()}>
                    <h5 className="col-11 offset-1">Versement des remboursements</h5>
                </div>
                <AelBankAccount
                    errors={errors}
                    values={values}
                    touched={touched}
                    dirty={dirty}
                    labelTitulaire="Je suis titulaire du compte à crediter"
                    nameBic="bicRemboursement"
                    nameIban="ibanRemboursement"
                    nameTitulaire="titulaireRemboursement"
                    nameCompteProfessionnel="compteProfessionnelRemboursement"
                    hidden={!this.displayCompteRemboursement()}
                    setFieldValue={setFieldValue}
                    disableCompteProfessionnel={true}
                    disabled={disabled}
                />
            </AelWizard.Step>
        )
    }
}

export default AelStep3
