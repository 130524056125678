import * as React from "react"
import AelStep2Service from "../services/aelStep2Service"
import AelStep2Dates from "./aelStep2Dates"
import AelWizard from "./aelWizard"
import Moment from "moment"
import "moment/locale/fr"
import AelOffre from "./aelOffre"
import AelStep2Besoins from "./aelStep2Besoins"
import AelPropositions from "./aelPropositions"
import { Col } from "react-bootstrap"
import Spinner from "react-bootstrap/Spinner"
import MgcSelectField from "./mgcSelectField"
import GAnalyticsService from "../services/gAnalyticsService"

class AelStep2 extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      dates: [],
      propositions: [],
      offres: [],
      fetchedDates: false,
      loading: false,
      questions: [],
      conseil1: null,
      conseil2: null,
      reprise: false,
    }
    this.onSelectDate = this.onSelectDate.bind(this)
    this.getPropositions = this.getPropositions.bind(this)
    this.onSelectProposition = this.onSelectProposition.bind(this)
    this.disabledButtonGetPropositions = this.disabledButtonGetPropositions.bind(
      this
    )
    this.setSpecificDDAValue = this.setSpecificDDAValue.bind(this)
    this.propositionRef = React.createRef()
  }

  componentDidMount() {
    GAnalyticsService.sendPageviw("/mgc-adhesion-etape2")
    Moment().locale("fr")
    const screenSize = window.innerWidth
    if (screenSize < 481) {
      window.scrollTo(0, 200)
    } else if (screenSize < 769) {
      window.scrollTo(0, 225)
    } else {
      window.scrollTo(0, 0)
    }
    const { values, validateForm } = this.props
    const {
      adherentDateNaissance,
      adherentRegimeAssurance,
      adherentSituationProfessionelle,
      enfants,
      dtDebut,
    } = values
    const hasChildren = enfants && enfants.length && enfants.length > 0
    AelStep2Service.getDates(
      adherentDateNaissance || Date.parse("1988-02-24"),
      adherentRegimeAssurance || "CPAM",
      adherentSituationProfessionelle || "SALA",
      hasChildren
    ).then(
      result => {
        const datesFromData = result.data.map(d => {
          return {
            date: d,
            display: Moment(d).format("Do MMMM YYYY"),
          }
        })
        this.setState({ dates: datesFromData })
        if (dtDebut) {
          this.setState({ reprise: true })
          this.onSelectDate(dtDebut)
        }
        validateForm()
      },
      reason => {
        console.error(reason)
      }
    )
  }

  getPropositions() {
    const { waitingBackStep2, setFieldValue, errors } = this.props
    if (errors.besoins) {
      return
    }
    this.setState({
      propositions: [],
      loading: true,
      conseil1: null,
      conseil2: null,
    })
    const { dtDebut, id, formule } = this.props.values
    if (dtDebut && id) {
      AelStep2Service.sendDDA(this.props.values).then(result => {
        const { edealFormuleConseil1 } = result.data
        AelStep2Service.getPropositions(dtDebut, id).then(result => {
          const propositions = result.data.propositions.map(p => {
            const { cleEdeal } = p.formule
            return {
              ...p,
              conseil:
                cleEdeal === edealFormuleConseil1
            }
          })
          this.setState({
            propositions: propositions,
            loading: false,
          })
          if (propositions && propositions.length > 0) {
            const { gamme } = propositions[0]
            const codeGamme = gamme.code
            setFieldValue("codeGamme", codeGamme)
            GAnalyticsService.sendPageviw(`/mgc-adhesion-${codeGamme}-etape2`)
            GAnalyticsService.sendEvent(
              `Adhésion en ligne`,
              "Tarification",
              codeGamme
            )
          }

          waitingBackStep2(false)
          const { reprise } = this.state
          if (reprise) {
            this.onSelectProposition(
              propositions.find(p => p.formule.id === formule)
            )
            this.setState({ reprise: false })
          }
          if (typeof window !== "undefined" && this.propositionRef.current) {
            window.scrollTo(0, this.propositionRef.current.offsetTop)
          }
        })
      })
    }
  }
  getOffres() {
    const { dtDebut, id } = this.props.values
    AelStep2Service.getOffres(dtDebut, id).then(result => {
      this.setState({ offres: result.data })
    })
  }

  getQuestions() {
    const { setFieldValue, values, validateForm, errors } = this.props
    const { dtDebut, email } = values
    AelStep2Service.getQuestions(email, dtDebut).then(async r => {
      this.setState({
        questions: r.data,
      })
      await this.setSpecificDDAValue(r.data, setFieldValue)
      //this.setDDAAnswers()
      await validateForm()

      if (values.besoins && values.besoins.id) {
        this.getPropositions()
      }
    })
  }

  async setSpecificDDAValue(questions) {
    const { validateForm, setFieldValue } = this.props
    if (
      questions.find(q => {
        return q.path === "couvertureZen"
      })
    ) {
      await setFieldValue("besoins.zen", true)
    }
    if (
      questions.find(q => {
        return q.path === "miniHospi"
      })
    ) {
      await setFieldValue("besoins.tns", true)
    }
    validateForm()
  }

  setDDAAnswers() {
    const { setValues, values, setFieldValue } = this.props
    setValues(values)
    setFieldValue("besoins.depassement", true)
  }
  async onSelectDate(value) {
    const { setFieldValue } = this.props
    this.setState({ propositions: [] })
    if (!this.state.reprise) {
      setFieldValue("idProposition", null)
      setFieldValue("formule", null)
    }

    await setFieldValue("dtDebut", value)

    this.getOffres()
    this.getQuestions()
  }

  onSelectProposition(proposition) {
    //p.identifiant, p.formule.id
    const { identifiant, formule, cotisMensuelle } = proposition || {}
    const { id } = formule
    const { setFieldValue } = this.props
    setFieldValue("idProposition", identifiant)
    setFieldValue("cotisationMensuelle", cotisMensuelle)
    setFieldValue("formule", id)
    this.setState({
      propositions: this.state.propositions.map(p => {
        return { ...p, selected: p.identifiant === identifiant }
      }),
    })
  }
  disabledButtonGetPropositions() {
    const { errors, dirty, disabled } = this.props
    const { questions, loading } = this.state
    return (
      errors.besoins ||
      !dirty ||
      !questions ||
      questions.length <= 0 ||
      disabled ||
      loading
    )
  }
  render() {
    const {
      errors,
      dirty,
      values,
      touched,
      setFieldValue,
      rollback,
      disabled,
      done,
    } = this.props
    const { dates, offres, propositions, loading, questions } = this.state
    return (
      <AelWizard.Step>
        <div className="form-row">
          <div className="title-rollback-ael  offset-1">
            <h2 className={"step-title"}>Garanties</h2>
          </div>
          <button
            type="button"
            className="btn-rollback-ael"
            onClick={() => rollback(1)}
            hidden={!done}
          >
            {disabled ? "Modifier" : "Consulter"}
          </button>
        </div>
        <AelStep2Dates
          datesRapides={dates.slice(0, 4)}
          dateAutres={dates.length > 4 ? dates.slice(4) : []}
          onSelect={this.onSelectDate}
          errors={errors}
          dirty={dirty}
          setFieldValue={setFieldValue}
          currentDate={values.dtDebut}
          disabled={disabled}
        />
        <AelOffre offres={offres} />
        <AelStep2Besoins
          touched={touched["besoins"] || {}}
          values={values["besoins"] || {}}
          errors={errors["besoins"] || {}}
          setFieldValue={setFieldValue}
          dirty={dirty}
          questions={questions}
          disabled={disabled}
        />
        <div className="homeButtonsFlex">
          <button
            type="button"
            className={`buttonAel ${
              this.disabledButtonGetPropositions() ? "disabled" : ""
            }`}
            disabled={this.disabledButtonGetPropositions()}
            onClick={this.getPropositions}
          >
            VALIDER
          </button>
        </div>

        <div
          className="row mt-4"
          hidden={!propositions || propositions.length <= 0}
        >
          <p className="offset-1" ref={this.propositionRef}>
            Choix des garanties: Pour valider votre choix de l'une des garanties
            ci-dessous, veuillez cliquer sur le bouton choisir, puis valider.
          </p>
        </div>
        <div className="row">
          <Col
            className="ptop spinner"
            xs={{ span: 8, offset: 2 }}
            lg={{ span: 2, offset: 5 }}
            hidden={!loading}
          >
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </Col>
        </div>

        <AelPropositions
          propositions={propositions}
          onSelect={this.onSelectProposition}
          disabled={disabled}
        />
      </AelWizard.Step>
    )
  }
}

export default AelStep2
