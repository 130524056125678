import * as React from "react"
import AelStep2BesoinQuestion from "./aelStep2BesoinQuestion"
import PropTypes from 'prop-types';
import MgcRadioToggleButtonGroup from "./mgcRadioToggleButtonGroup"
import { Field } from "formik"
import MgcRadioToggleButton from "./mgcRadioToggleButton"
const AelStep2Besoins = ({
  title,
  errors,
  dirty,
  values,
  touched,
  setFieldValue,
  questions,
  disabled
}) => {
  // cas particulier pour l question couverture Zen ce n'est pas un booleen
  const zenQuestion = questions.find(q => {return q.path === 'couvertureZen'})
  const filteredQuestions = questions.filter(q => {return q.path !== 'couvertureZen'})
  return (
    <div hidden={!questions || questions.length <= 0}>
      <div className="form-row">
        <h3 className="offset-1">Besoins</h3>
      </div>
      <div className="form-row">
        <p className="offset-1">Vous ou votre conjoint&#160;:</p>
      </div>
      {zenQuestion && (
        <>
          <div className="form-row">
              <span className="col-11 offset-1" dangerouslySetInnerHTML={{ __html: zenQuestion.labelQuestion}} />
          </div>
          <MgcRadioToggleButtonGroup id={`besoins.${zenQuestion.path}`} value={values[zenQuestion.path]}
                                     error={errors[zenQuestion.path]}
                                     touched={touched[zenQuestion.path]}

          >
            <Field component={MgcRadioToggleButton} name={`besoins.${zenQuestion.path}`} id="TARIF"
                   label={"Tarif"} disabled={disabled}/>
            <Field component={MgcRadioToggleButton} name={`besoins.${zenQuestion.path}`} id="NIVEAU_PRESTA"
                   label={"Niveau de prestation"} disabled={disabled}/>
          </MgcRadioToggleButtonGroup>
        </>
      )}
      {filteredQuestions && filteredQuestions.filter(q => {return q.labelQuestion && q.path && q.path !== 'couvertureZen'}).map(question => {
        return (<AelStep2BesoinQuestion name={question.path} errors={errors} values={values} touched={touched} question={question.labelQuestion} disabled={disabled} />)
      })}
    </div>
  )
}

AelStep2Besoins.propTypes = {
  questions: PropTypes.array,
}
export default AelStep2Besoins;
