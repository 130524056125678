import { Field } from "formik"
import * as React from "react"
import MgcTextField from "./mgcTextField"
import MgcRadioToggleButtonGroup from "./mgcRadioToggleButtonGroup"
import MgcRadioToggleButton from "./mgcRadioToggleButton"
import { hasOnlyNumbers } from "../utils/regexTests"

const AelTns = ({ errors, dirty, hidden, values, touched, disabled, setFieldValue }) => (
  <div hidden={hidden}>
    <MgcTextField
      dirty={dirty} errors={errors}
      name="entrepriseTNSCodePostal"
      placeholder="Code postal de l'entreprise"
      onChange={e => {
        if (hasOnlyNumbers(e.target.value)) {
          setFieldValue(e.target.name, e.target.value)
        }
      }}
    />
    <MgcTextField dirty={dirty} errors={errors} name="entrepriseTNSVille" placeholder="Ville de l'entreprise" disabled={disabled} />
    <MgcTextField dirty={dirty} errors={errors} name="entrepriseTNSNom" placeholder="Nom de l'entreprise" disabled={disabled} />
    <MgcTextField dirty={dirty} errors={errors} name="entrepriseTNSSiret" placeholder="SIRET de l'entreprise" disabled={disabled} />
    <MgcRadioToggleButtonGroup touched={touched} value={values.entrepriseTNSSalaries} label="Avez-vous des salariés"
      error={errors.entrepriseTNSSalaries} id="entrepriseTNSSalaries">
      <Field component={MgcRadioToggleButton} name="entrepriseTNSSalaries" id="true" label={"Oui"} disabled={disabled} />
      <Field component={MgcRadioToggleButton} name="entrepriseTNSSalaries" id="false" label={"Non"} disabled={disabled} />
    </MgcRadioToggleButtonGroup>
  </div>
);

export default AelTns;
