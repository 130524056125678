import { Field } from "formik"
import * as React from "react"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Tooltip from "react-bootstrap/Tooltip"
import MgcEmailField from "./mgcEmailField"

const MgcPasswordField = ({
  name,
  placeholder,
  errors,
  dirty,
  hidden,
  tooltip,
  disabled
}) => (
  <div className="form-row" hidden={hidden}>
    <div className="col-1 text-right">
      {tooltip && (
        <OverlayTrigger
          overlay={
            <Tooltip id="title-tooltip" placement={"right"}>
              <p>{tooltip}</p>
            </Tooltip>
          }
        >
          <span className="fa-exclamation-circle fa-2x fa" title="" />
        </OverlayTrigger>
      )}
    </div>
    <div className="col-10 form-group">
      <Field
        type="password"
        name={name}
        className="form-control"
        placeholder={placeholder}
        disabled={disabled}
      />
    </div>
    <div className="col-1">
      <span
        className={`${
          errors[name] || !dirty
            ? "fa-times text-danger"
            : "fa-check text-success"
        } fa-2x fa`}
      />
    </div>
  </div>
)

export default MgcPasswordField
