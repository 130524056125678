import React from "react"
import FormulaLevel from "./FormulaLevel"
import { Button, Col, Collapse, Row } from "react-bootstrap"
import classNames from "classnames"
import { EcheancierService } from "../services/echeancierService"
import "./quotationFormula.css"

class QuotationFormula extends React.Component {
  state = {
    iconClass: "",
    date1: "",
    defaultActiveKey: null,
    formulaSelected: "",
  }
  monthNames = [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
    "Janvier",
  ]
  constructor(props) {
    super(props)

  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { formules } = this.props
    const defaultActiveKey = formules.find(f => f.selected)
      ? formules.find(f => f.selected).formule.id
      : null
    if (prevProps.formules !== formules) {
      this.setState({ defaultActiveKey: defaultActiveKey })
      let tab = [];
      if (formules) {
        formules.map((e, index) => {
          e.conseil && tab.push(index)
        })
        this.isFirst(tab)
      }
    }
  }

  dateFinDisplay(echeancier) {
    if (echeancier.dateDebut !== echeancier.dateFin) {
      return ` à ${this.monthNames[new Date(echeancier.dateFin).getMonth()]} ${
        new Date(echeancier.dateFin).getMonth() === 12
          ? new Date(echeancier.dateFin).getFullYear() + 1
          : new Date(echeancier.dateFin).getFullYear()
      }`
    }
    return ""
  }
  echeancierDisplay(echeancier) {
    return (
      <>
        <br />- {this.monthNames[new Date(echeancier.dateDebut).getMonth()]}{" "}
        {new Date(echeancier.dateDebut).getFullYear()}
        {this.dateFinDisplay(echeancier)}
        &nbsp;:{" "}
        {echeancier.montant.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}
        €/mois
      </>
    )
  }

  isFirst(tab) {
      const newTab = [...tab];
      this.setState({ firstCouncil: newTab })
  }

  handelChangeFormula(idFomule) {
    this.state.formulaSelected === idFomule
      ? this.setState({ formulaSelected: null })
      : this.setState({ formulaSelected: idFomule })
  }

  render() {
    const { formules, button, onClickButton, tns, disabled } = this.props

    return (
      <>
        {formules.map((e) => {
          return (
            <>
              <div className="row conseil-header">
                <span
                  hidden={!e.conseil}
                  className={"col-2 conseil-header"}
                >
                  Conseil MGC
                </span>
              </div>
              <div
                className={`container formula-wrapper ${
                  e.conseil ? "conseil" : ""
                  }`}
              >
                <div className="quotation-formula-header" key={e.numero + 198}>
                  <div
                    className="quotation-formula-libelle"
                    key={e.numero}
                    onClick={() => this.handelChangeFormula(e.formule.id)}
                  >
                    {e.formule.libelle}
                  </div>
                  <div
                    className="quotation-formula-price"
                    key={e.numero + 1}
                    onClick={() => this.handelChangeFormula(e.formule.id)}
                  >
                    {new Intl.NumberFormat("fr-FR", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(e.cotisMensuelle)}
                    €/mois
                  </div>
                </div>
                <div
                  className="quotation-formule"
                  key={e.numero + 10}
                  onClick={() => this.handelChangeFormula(e.formule.id)}
                >
                  <div
                    as={Button}
                    variant="link"
                    className="formulaAccordionSectionTitle"
                  >
                    {e.formule.presentation}
                    <i
                      className={
                        this.state.formulaSelected === e.formule.id
                          ? "faqAccordionSectionIcon fas fa-chevron-down fa-2x"
                          : "faqAccordionSectionIcon fas fa-chevron-right fa-2x"
                      }
                    />
                  </div>
                  <Row key={e.numero + 12}>
                    <Col key={e.numero + 14} xl={3} md={6} xs={12}>
                      <FormulaLevel
                        level={e.formule.niveauSoins}
                        classIcon="icon-drug"
                        rootClass="formuleHeaderDescriptionTableItem"
                        tns={tns}
                        maxLevel={e.formule.niveauMax || 5}
                      />
                    </Col>
                    <Col key={e.numero + 120} xl={3} md={6} xs={12}>
                      <FormulaLevel
                        level={e.formule.niveauHospi}
                        classIcon="icon-hospital"
                        rootClass="formuleHeaderDescriptionTableItem"
                        tns={tns}
                        maxLevel={e.formule.niveauMax || 5}
                      />
                    </Col>
                    <Col key={e.numero + 20} xl={3} md={6} xs={12}>
                      <FormulaLevel
                        level={e.formule.niveauOptique}
                        classIcon="far fa-eye"
                        rootClass="formuleHeaderDescriptionTableItem"
                        tns={tns}
                        maxLevel={e.formule.niveauMax || 5}
                      />
                    </Col>
                    <Col key={e.numero + 180} xl={3} md={6} xs={12}>
                      <FormulaLevel
                        level={e.formule.niveauDentaire}
                        classIcon="icon-tooth"
                        rootClass="formuleHeaderDescriptionTableItem"
                        tns={tns}
                        maxLevel={e.formule.niveauMax || 5}
                      />
                    </Col>
                    <Col key={e.numero + 200} xl={3} md={6} xs={12}>
                      <FormulaLevel
                        level={e.formule.niveauMedecineDouce}
                        classIcon="icon-hospital"
                        rootClass="formuleHeaderDescriptionTableItem"
                        tns={tns}
                        maxLevel={e.formule.niveauMedecineDouce || 5}/>
                    </Col>
                    <Col key={e.numero + 240} xl={3} md={6} xs={12}>
                      <FormulaLevel
                        level={e.formule.niveauAidesAuditives}
                        classIcon="fas fa-assistive-listening-systems"
                        rootClass="formuleHeaderDescriptionTableItem"
                        tns={tns}
                        maxLevel={e.formule.niveauAidesAuditives || 5}
                      />
                    </Col>
                  </Row>
                  <Collapse
                    in={
                      e.formule.id === this.state.defaultActiveKey ||
                      e.formule.id === this.state.formulaSelected
                    }
                  >
                    <Row>
                      <Col
                        xs={11}
                        md={8}
                        lg={9}
                        className="quotation-echancier-step-2"
                      >
                        <span>Échéancier prévisionnel&nbsp;:</span>
                        <div>
                          {EcheancierService.echeancierToEcheancierDisplay(
                            e.echeancier
                          ).map((ech, index) => {
                            return this.echeancierDisplay(ech)
                          })}
                        </div>
                      </Col>
                      {button ? (
                        <Col xs={12} md={4} lg={3}>
                          <button
                            type="button"
                            className={classNames("buttonAel btn-formule", {
                              selected: e.selected,
                            })}
                            disabled={disabled}
                            onClick={() => onClickButton(e)}
                          >
                            {e.selected ? "Formule choisie" : "Choisir"}
                          </button>
                        </Col>
                      ) : (
                        ""
                      )}
                    </Row>
                  </Collapse>
                </div>
              </div>
            </>
          )
        })}
      </>
    )
  }
}
export default QuotationFormula
