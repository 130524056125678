import MgcRadioGroup from "./mgcRadioGroup"
import { Field } from "formik"
import * as React from "react"
import MgcRadio from "./mgcRadio"

const AelConjointRattachement = ({errors, dirty, values, touched, setFieldValue, hidden, disabled, adherentNSS}) => (
  <div hidden={hidden}>
    <MgcRadioGroup touched={touched} value={values.conjointRattachement} error={errors.conjointRattachement} id="conjointRattachement" >
      <Field component={MgcRadio} name="conjointRattachement" id="true" label={`Rattaché à l'adhérent principal (${adherentNSS || "N° à saisir"})`} disabled={disabled}/>
      <Field component={MgcRadio} name="conjointRattachement" id="false" label={"Numéro personnel"}  disabled={disabled}/>
    </MgcRadioGroup>
  </div>
);

export default AelConjointRattachement;
