import * as React from "react"

const AelOffre = ({ offres }) => {
  return (
    <div hidden={!offres || !offres.length || offres.length < 1}>
      <div className="form-row">
        <p className="offset-1">Offre promotionnelle :</p>
        <div className="text-success">
          <ul>
            {offres &&
              offres.length &&
              offres.map(o => {
                return <li key={o.id}>{o.description}</li>
              })}
          </ul>
        </div>
      </div>
      <div className="form-row">
        <p className="offset-1">
          <a href="/mutuelle-sante/offres-promotionnelles/" target="_blank">
            Voir les conditions des offres
          </a>
        </p>
      </div>
    </div>
  )
}

export default AelOffre
