import React from "react"
import { isMobile, deviceDetect } from "react-device-detect"
class FileUpload extends React.Component {
  state = {
    fileName: "Aucun fichier choisi",
    btnText: "Parcourir",
  }
  constructor(props) {
    super(props)
    this.inputFile = React.createRef()
  }

  componentDidMount() {
    const { field, piece } = this.props;
    if (piece && piece.size !== 0) {
      this.setState({ fileName: field.name + '.jpg' })
    }
  }
  
  getFile() {
    this.inputFile.current.click()
  }
  handleChange(e) {
    const { field, form } = this.props
    const file = e.currentTarget.files[0]
    this.setState({ fileName: file.name })
    let reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = e => {
      //var array = new Uint32Array(reader.result) // read the actual file contents
      //console.log("_+_array:", array) // the array is empty!
      //var binaryString = String.fromCharCode.apply(null, array)
      //console.log("__binaryString:", e.target.result)
      let encoded = reader.result.replace(/^data:(.*;base64,)?/, "")
      if (encoded.length % 4 > 0) {
        encoded += "=".repeat(4 - (encoded.length % 4))
      }
      form.setFieldValue(`${field.name}.content`, encoded)
    }

    form.setFieldValue(`${field.name}.contentType`, file.type)
    form.setFieldValue(`${field.name}.size`, file.size)
  }

  render() {
    const { disabled } = this.props
    return (
      <div className="custom-file-upload">
        <input
          type="file"
          onChange={event => this.handleChange(event)}
          className="form-control-file"
          disabled={disabled}
          style={{ display: "none" }}
          ref={this.inputFile}
          accept="image/*"
        />
        <button
          onClick={() => this.getFile()}
          className={"ael-step4-input-btn"}
          type={'button'}
          disabled={disabled}
        >
          {isMobile ? "Parcourir ou" : "Parcourir"}{" "}
          {isMobile && <i className={"fa fa-camera"} />}
        </button>
        <input
          type="text"
          readOnly="readonly"
          id="file_avatar"
          onClick={() => this.getFile()}
          value={this.state.fileName}
          className={"ael-step4-input-text"}
          disabled={disabled}
        />
      </div>
    )
  }
}

export default FileUpload
