import React, { useState } from "react"
import classNames from "classnames"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Tooltip from "react-bootstrap/Tooltip"
import informationIcon from "../images/icon-information.png"
import { Field } from "formik"
import {
  maskCompletePhoneNumber,
  maskPhoneNumber,
} from "../services/global/fieldMasks"
import MaskedInput from "react-text-mask"

function MgcPhoneField({
  name,
  label,
  placeholder,
  errors,
  hidden,
  tooltip,
  withoutOffset,
  disabled,
  values,
  dirty,
  prefix,
  setFieldValue,
}) {
  const fieldName = prefix ? `${prefix}${name}` : name
  const error = errors[name] || !dirty

  const [activeMask, setActiveMask] = useState(getMask(values[name]))

  const formGroupClassNames = classNames("form-group", {
    "col-10 offset-1": !tooltip && !withoutOffset,
    "col-11": withoutOffset && !tooltip,
    "col-10": tooltip,
  })

  const checkerClassNames = classNames("fa fa-2x", {
    "fa-times text-danger": error,
    "fa-check text-success": !error,
  })

  function getMask(value) {
    return value && value.length > 0
      ? value[0] === "+"
        ? maskCompletePhoneNumber
        : maskPhoneNumber
      : [/[+0-9]/]
  }

  return (
    <div className="form-row" hidden={hidden}>
      {tooltip && (
        <div className="col-1 text-right my-auto">
          <OverlayTrigger
            overlay={
              <Tooltip id="title-tooltip" placement={"right"}>
                <p>{tooltip}</p>
              </Tooltip>
            }
          >
            <img src={informationIcon} className="icon-info-ael" />
          </OverlayTrigger>
        </div>
      )}
      <div className={formGroupClassNames}>
        {!!label && <label>{label}</label>}
        <Field
          type="text"
          name={fieldName}
          disabled={disabled}
          render={({ field }) => (
            <MaskedInput
              mask={activeMask}
              {...field}
              placeholder={placeholder}
              guide={false}
              id={fieldName}
              className="form-control"
              disabled={disabled}
              onChange={e => {
                const inputValue = e.target.value
                if (inputValue.length === 0 || inputValue.length === 1) {
                  setActiveMask(getMask(inputValue))
                }
                setFieldValue(e.target.name, inputValue)
              }}
              onBlur={e => {
                setFieldValue(e.target.name, e.target.value.replaceAll(" ", ""))
              }}
            />
          )}
        />
        <div className="invalid-feedback">{error}</div>
      </div>
      <div className={`col-1${!!label && " mt-30px"}`}>
        <span className={checkerClassNames} />
      </div>
    </div>
  )
}

export default MgcPhoneField
